<template>
  <main>
    <div class="form">

      <ui-input label="*receiver:" name="receiver" v-model="receiver"></ui-input>
      <ui-input
              label="*Tel:"
              name="Tel"
              v-model="Tel"
      ></ui-input>
        <ui-input
                label="*Email:"
                name="Email"
                v-model="Email"
        ></ui-input>
<!--&lt;!&ndash;      大洲&ndash;&gt;-->
<!--     <div class="address-div" >-->
<!--       <span class="address-label">*continent:</span>-->
<!--       <select name="continent" class="address-select" v-model="continent1"   @change="switchContinent($event, $event.target.selectedIndex)">-->
<!--         <option v-for="item in continentList" :value="item" :key="item.index"  >-->
<!--           {{item}}-->
<!--         </option>-->
<!--       </select>-->
<!--     </div>-->

<!--&lt;!&ndash;     国&ndash;&gt;-->
<!--        <div class="address-div">-->
<!--            <span class="address-label">*country:</span>-->
<!--            <select  name="country" class="address-select" v-model="country1"   @change="switchCountry($event, $event.target.selectedIndex)">-->
<!--                <option v-for="item in countryList" :value="item.text" :key="item.value"  >-->
<!--                    {{item.text}}-->
<!--                </option>-->
<!--            </select>-->
<!--        </div>-->

<!--&lt;!&ndash;        省&ndash;&gt;-->
<!--        <div class="address-div"  >-->
<!--            <span class="address-label">*province:</span>-->
<!--            <select v-if="provinceList.length!==0"  name="province" class="address-select" v-model="province1"   @change="switchProvince($event, $event.target.selectedIndex)">-->
<!--                <option v-for="item in provinceList" :value="item.text" :key="item.value" >-->
<!--                    {{item.text}}-->
<!--                </option>-->
<!--            </select>-->
<!--            <input v-else class="address-input"  type="text" name="province2" v-model="province1">-->
<!--        </div>-->

<!--&lt;!&ndash;        市&ndash;&gt;-->
<!--        <div class="address-div"  v-show="cityList" >-->
<!--            <span class="address-label">*city:</span>-->
<!--            <select v-if="cityList.length!==0" name="city" class="address-select" v-model="city1"   @change="switchCity($event, $event.target.selectedIndex)">-->
<!--                <option v-for="item in cityList" :value="item.text" :key="item.value" >-->
<!--                    {{item.text}}-->
<!--                </option>-->
<!--            </select>-->
<!--            <input class="address-input" v-else type="text" name="city2" v-model="city1">-->
<!--        </div>-->
      <ui-input
              label="*address1:"
              name="address1"
              v-model="address1"
      ></ui-input>
      <ui-input
              label="*address2:"
              name="address2"
              v-model="address2"
      ></ui-input>
      <ui-input
              label="*postcode:"
              name="postcode"
              v-model="postcode"
      ></ui-input>
      <div class="save-area">
        <button class="save" @click="save">save</button>
        <a href="javascript:;" @click="back">cancel</a>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from "vue";
  import axios from "@/api/axios";
  import UiInput from "@/components/UI/Input";
  import UiSelect from "@/components/UI/Select";
  import world from "@/components/UI/Select/src/addressJson.js";
  import Toast from "@/components/UI/Toast";
  import qs from "qs";
  import { useRouter, useRoute } from "vue-router";
  import {log} from "echarts/types/src/util/log";

  console.log(world)

  export interface Iaddress {
    receiver: string;
    contactTel: string;
    address: string;
    receiveAddress: string;
    isDefault: boolean;
    province: string;
    country: string;
    city: string;
    continent: string;
    address2: string;
    Postcode: string;
  }
  export default defineComponent({
    name: "addAddress",
    components: {
      UiInput,
    },
    data() {
      return {
        continentList: ["North America","Asia","Europe","Africa","South America","Oceania","Antarctica"],
        countryList:[],
        provinceList: [],
        cityList:[],
          continentIndex:0,
          countryIndex:0,
          provinceIndex:0,
          cityIndex:0,
          //地址信息
          continent1:'',
          country1:'',
          province1:'',
          // province2:'',
          city1:'',
          // city2:'',
          address1:'',
          address2:'',
          //用户信息
          receiver:'',
          tel:'',
          postcode:'',
          Email:''
      }
    },
    methods: {
        // 监听大洲选择的索引
      switchContinent(item: any,index: any) {
          this.continentIndex=index;
          this.countryIndex=0;
          this.provinceIndex=0;
          this.countryList=[];
          this.provinceList=[];
          this.cityList=[];
          this.province1='';
          this.city1='';
          //控制显示大洲国默认第一国
          world[index].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en
              this.countryList.push(obj)
              this.country1=this.countryList[0].text
          } );

            //控制选择省
          world[index].childrens[0].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en
              this.provinceList.push(obj)
              this.province1=this.provinceList[0].text
          } );

          //控制选择市
          world[index].childrens[0].childrens[0].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en;
              this.cityList.push(obj)
              this.city1=this.cityList[0].text
          } );

      },
        // 监听国家选择的索引
        switchCountry(item: any,index: any) {
            this.countryIndex=index;
            this.provinceList=[];
            this.cityList=[];
            this.province1='';
            this.city1='';
            //控制显示省
            if(world[this.continentIndex].childrens[index].childrens!==[]){
                world[this.continentIndex].childrens[index].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.provinceList.push(obj)
                    this.province1=this.provinceList[0].text;
                } );
                //控制显示市 默认第一
                // world[this.continentIndex].childrens[index].childrens[0].childrens.map((item: any,index: any)=>{
                //     const obj={value:1,text:''};
                //     obj.value=index; obj.text=item.name_en;
                //     this.cityList.push(obj)
                //     this.city1=this.cityList[0].text
                // } );

            }


        },
        //监听省选择
        switchProvince(item: any,index: any) {
            this.city1='';
            this.cityList=[];
            if( world[this.continentIndex].childrens[this.countryIndex].childrens[index].childrens!==[]){
                world[this.continentIndex].childrens[this.countryIndex].childrens[index].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.cityList.push(obj)
                    this.city1=this.cityList[0].text
                } );

            }
        },

    },

    mounted() {


        //如有地址则获取当前地址
        if (this.id) {
            axios.get("/M/User/AddAddress/" + this.id).then((res) => {

                // data.isDefault == 1?data.isDefault = true:data.isDefault=false
                // console.log(res.data.obj.address);
                this.continent1= res.data.obj.address.continent;
                this.province1= res.data.obj.address.province;
                this.country1= res.data.obj.address.country;
                this.city1= res.data.obj.address.city;
                this.Tel= res.data.obj.address.contactTel;
                this.Email= res.data.obj.address.contactEmail;
                this.receiver= res.data.obj.address.receiver;
                this.postcode= res.data.obj.address.postcode;
                this.address1= res.data.obj.address.receiveAddress;
                this.address2= res.data.obj.address.receiveAddress2;
                //获取大洲确定国选择项
                this.continentIndex= this.continentList.indexOf(this.continent1);
                // console.log(this.continentIndex);
                world[this.continentIndex].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.countryList.push(obj)
                } );

                //根据国确定省
                 this.countryList.map((item: any, index: any)=>{
                   if(this.country1===item.text) {this.countryIndex=index}});
                if(world[this.continentIndex].childrens[this.countryIndex].childrens.length!==0){
                    world[this.continentIndex].childrens[this.countryIndex].childrens.map((item: any,index: any)=>{
                        const obj={value:1,text:''};
                        obj.value=index; obj.text=item.name_en;
                        this.provinceList.push(obj)
                    } );
                }
                // console.log( this.countryIndex,this.countryList,this.provinceList)
                //根据省确定市

                if(this.provinceList.length!==0){
                    this.provinceList.map((item: any, index: any)=>{
                        if( this.province1===item.text) {
                            this.provinceIndex=index
                        }
                    });
                }
                if( world[this.continentIndex].childrens[this.countryIndex].childrens[this.provinceIndex].childrens.length!==0){
                    world[this.continentIndex].childrens[this.countryIndex].childrens[this.provinceIndex].childrens.map((item: any,index: any)=>{
                        const obj={value:1,text:''};
                        obj.value=index; obj.text=item.name_en;
                        this.cityList.push(obj)
                    } );
                }else {
                    this.cityList=[]
                }
                this.city1= res.data.obj.address.city

              this.Tel= res.data.obj.address.contactTel
              this.Email= res.data.obj.address.contactEmail
              this.receiver= res.data.obj.address.receiver
              this.postcode= res.data.obj.address.postcode
              this.address1= res.data.obj.address.receiveAddress
              this.address2= res.data.obj.address.receiveAddress2


            }).catch((err) => {
                    console.log(err);
                });
        }else {
            //默认大洲
            world.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.continentList.push(obj)
            });
            this.continent1=this.continentList[0].text;
            //默认国家
            world[0].childrens.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.countryList.push(obj)
            });
            this.country1=this.countryList[0].text;
            //默认省份
            world[0].childrens[0].childrens.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.provinceList.push(obj)
            } );
            // this.province1=this.provinceList[0].text
        }
    },
    setup() {
      const route = useRoute();
        const router = useRouter();
      const id = route.query.id || "";
        function back() {
            router.go(-1);
        }
        function save() {
            console.log(this.tel);
            const data=qs.stringify({
                id:id,
                continent:this.continent1,
                province:this.province1,
                country:this.country1,
                city:this.city1,
                receiver:this.receiver,
                contactEmail:this.Email,
                receiveAddress:this.address1,
                receiveAddress2:this.address2,
                contactTel:this.Tel,
                postcode:this.postcode
            });

            if(!this.continent1||!this.province1||!this.country1||!this.city1||!this.receiver||!this.Email||
                !this.address1||!this.address2||!this.Tel||!this.postcode){
                Toast({
                    type: "error",
                    title: 'Please complete the address！'
                });
                return false
            }
            axios.post("/M/User/SaveAddress",data)
                .then((res) => {
                    if (res.data.success) {
                        Toast({
                            type: "success",
                            title: res.data.msg,
                            onClose: () => {
                                back();
                            },
                        });
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
      return {
          id,
          back,
          save
      };

    },
  });
</script>

<style lang="scss" scoped>
// .save-area {
//   width: 100%;
//   height: 1.2rem;
//   position: relative;
//   top: 1.333rem;
// }
// .save-area button {
//   width: 100%;
//   height: 1.2rem;
//   background-color: #d64b4b;
//   box-shadow: 0px 0.133rem 0.267rem 0px rgba(0, 0, 0, 0.3);
//   border-radius: 0.1rem;
//   line-height: 1.2rem;
//   text-align: center;
//   color: #ffffff;
//   border: none;
//   outline: none;
//   font-size: 0.4rem;
// }
.address-input{
    float: right;
    text-align: right;
    margin-right: 10px;
    height: 38px;
    line-height: 38px;
    border: 0;
    width: 65%;
    position: relative;
    top: 3px;
}
.address-div{
    height: 45px;border-bottom: 1px solid #eee;
}
.save-area {
  width: 100%;
  height: 45px;
  position: relative;
  top: 50px;
}
.address-label{
    font-size: 14px;
    height: 45px;
    line-height: 45px;
  color: #444444;
  margin-left: 10px;
    float: left;
}
.save-area a {
  margin-left: 5%;
  display: inline-block;
  width: 40%;
  height: 45px;
  background-color: #999;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff !important;
  font-size: 15px;
}

.save-area button {
  margin-left: 10%;
  width: 40%;
  height: 45px;
  background-color: #d64b4b;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 3.75px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
}
.form-item {
  padding-left: 2.4rem;
}
.switch-item {
  padding-left: 1.76rem;
  position: relative;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: right;
  padding-bottom: 1px;
}

.form-label {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: left;
  color: #444444;
  font-size: 0.373rem;
  padding: 0 0.267rem;
}

.mui-switch {
  width: 52px;
  height: 31px;
  position: relative;
  border: 1px solid #dfdfdf;
  background-color: #fdfdfd;
  box-shadow: #dfdfdf 0 0 0 0 inset;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-clip: content-box;
  display: inline-block;
  -webkit-appearance: none;
  user-select: none;
  outline: none;
}
.address-select{
    border: 0px solid rgb(238, 238, 238);
    background-color: rgba(11, 11, 11, 0);
    margin-right: 10px;
    font-size: 14px;
    float: right;
    width: 50%;
    overflow: hidden;
    height: 45px;

}
.address-select option{
    color: black;
    margin: 0px;
    text-align: right;
}
.mui-switch:before {
  content: "";
  width: 29px;
  height: 29px;
  position: absolute;
  top: 0px;
  left: 0;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.mui-switch:checked {
  border-color: #64bd63;
  box-shadow: #64bd63 0 0 0 16px inset;
  background-color: #64bd63;
}
.mui-switch:checked:before {
  left: 21px;
}
.mui-switch.mui-switch-animbg {
  transition: background-color ease 0.4s;
}
.mui-switch.mui-switch-animbg:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-animbg:checked {
  box-shadow: #dfdfdf 0 0 0 0 inset;
  background-color: #64bd63;
  transition: border-color 0.4s, background-color ease 0.4s;
}
.mui-switch.mui-switch-animbg:checked:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-anim {
  transition: border cubic-bezier(0, 0, 0, 1) 0.4s,
    box-shadow cubic-bezier(0, 0, 0, 1) 0.4s;
}
.mui-switch.mui-switch-anim:before {
  transition: left 0.3s;
}
.mui-switch.mui-switch-anim:checked {
  box-shadow: #64bd63 0 0 0 16px inset;
  background-color: #64bd63;
  transition: border ease 0.4s, box-shadow ease 0.4s, background-color ease 1.2s;
}
.mui-switch.mui-switch-anim:checked:before {
  transition: left 0.3s;
}
</style>
