
  import { defineComponent, ref, watch } from "vue";
  import axios from "@/api/axios";
  import UiInput from "@/components/UI/Input";
  import UiSelect from "@/components/UI/Select";
  import world from "@/components/UI/Select/src/addressJson.js";
  import Toast from "@/components/UI/Toast";
  import qs from "qs";
  import { useRouter, useRoute } from "vue-router";
  import {log} from "echarts/types/src/util/log";

  console.log(world)

  export interface Iaddress {
    receiver: string;
    contactTel: string;
    address: string;
    receiveAddress: string;
    isDefault: boolean;
    province: string;
    country: string;
    city: string;
    continent: string;
    address2: string;
    Postcode: string;
  }
  export default defineComponent({
    name: "addAddress",
    components: {
      UiInput,
    },
    data() {
      return {
        continentList: ["North America","Asia","Europe","Africa","South America","Oceania","Antarctica"],
        countryList:[],
        provinceList: [],
        cityList:[],
          continentIndex:0,
          countryIndex:0,
          provinceIndex:0,
          cityIndex:0,
          //地址信息
          continent1:'',
          country1:'',
          province1:'',
          // province2:'',
          city1:'',
          // city2:'',
          address1:'',
          address2:'',
          //用户信息
          receiver:'',
          tel:'',
          postcode:'',
          Email:''
      }
    },
    methods: {
        // 监听大洲选择的索引
      switchContinent(item: any,index: any) {
          this.continentIndex=index;
          this.countryIndex=0;
          this.provinceIndex=0;
          this.countryList=[];
          this.provinceList=[];
          this.cityList=[];
          this.province1='';
          this.city1='';
          //控制显示大洲国默认第一国
          world[index].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en
              this.countryList.push(obj)
              this.country1=this.countryList[0].text
          } );

            //控制选择省
          world[index].childrens[0].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en
              this.provinceList.push(obj)
              this.province1=this.provinceList[0].text
          } );

          //控制选择市
          world[index].childrens[0].childrens[0].childrens.map((item: any,index: any)=>{
              const obj={value:1,text:''};
              obj.value=index; obj.text=item.name_en;
              this.cityList.push(obj)
              this.city1=this.cityList[0].text
          } );

      },
        // 监听国家选择的索引
        switchCountry(item: any,index: any) {
            this.countryIndex=index;
            this.provinceList=[];
            this.cityList=[];
            this.province1='';
            this.city1='';
            //控制显示省
            if(world[this.continentIndex].childrens[index].childrens!==[]){
                world[this.continentIndex].childrens[index].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.provinceList.push(obj)
                    this.province1=this.provinceList[0].text;
                } );
                //控制显示市 默认第一
                // world[this.continentIndex].childrens[index].childrens[0].childrens.map((item: any,index: any)=>{
                //     const obj={value:1,text:''};
                //     obj.value=index; obj.text=item.name_en;
                //     this.cityList.push(obj)
                //     this.city1=this.cityList[0].text
                // } );

            }


        },
        //监听省选择
        switchProvince(item: any,index: any) {
            this.city1='';
            this.cityList=[];
            if( world[this.continentIndex].childrens[this.countryIndex].childrens[index].childrens!==[]){
                world[this.continentIndex].childrens[this.countryIndex].childrens[index].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.cityList.push(obj)
                    this.city1=this.cityList[0].text
                } );

            }
        },

    },

    mounted() {


        //如有地址则获取当前地址
        if (this.id) {
            axios.get("/M/User/AddAddress/" + this.id).then((res) => {

                // data.isDefault == 1?data.isDefault = true:data.isDefault=false
                // console.log(res.data.obj.address);
                this.continent1= res.data.obj.address.continent;
                this.province1= res.data.obj.address.province;
                this.country1= res.data.obj.address.country;
                this.city1= res.data.obj.address.city;
                this.Tel= res.data.obj.address.contactTel;
                this.Email= res.data.obj.address.contactEmail;
                this.receiver= res.data.obj.address.receiver;
                this.postcode= res.data.obj.address.postcode;
                this.address1= res.data.obj.address.receiveAddress;
                this.address2= res.data.obj.address.receiveAddress2;
                //获取大洲确定国选择项
                this.continentIndex= this.continentList.indexOf(this.continent1);
                // console.log(this.continentIndex);
                world[this.continentIndex].childrens.map((item: any,index: any)=>{
                    const obj={value:1,text:''};
                    obj.value=index; obj.text=item.name_en;
                    this.countryList.push(obj)
                } );

                //根据国确定省
                 this.countryList.map((item: any, index: any)=>{
                   if(this.country1===item.text) {this.countryIndex=index}});
                if(world[this.continentIndex].childrens[this.countryIndex].childrens.length!==0){
                    world[this.continentIndex].childrens[this.countryIndex].childrens.map((item: any,index: any)=>{
                        const obj={value:1,text:''};
                        obj.value=index; obj.text=item.name_en;
                        this.provinceList.push(obj)
                    } );
                }
                // console.log( this.countryIndex,this.countryList,this.provinceList)
                //根据省确定市

                if(this.provinceList.length!==0){
                    this.provinceList.map((item: any, index: any)=>{
                        if( this.province1===item.text) {
                            this.provinceIndex=index
                        }
                    });
                }
                if( world[this.continentIndex].childrens[this.countryIndex].childrens[this.provinceIndex].childrens.length!==0){
                    world[this.continentIndex].childrens[this.countryIndex].childrens[this.provinceIndex].childrens.map((item: any,index: any)=>{
                        const obj={value:1,text:''};
                        obj.value=index; obj.text=item.name_en;
                        this.cityList.push(obj)
                    } );
                }else {
                    this.cityList=[]
                }
                this.city1= res.data.obj.address.city

              this.Tel= res.data.obj.address.contactTel
              this.Email= res.data.obj.address.contactEmail
              this.receiver= res.data.obj.address.receiver
              this.postcode= res.data.obj.address.postcode
              this.address1= res.data.obj.address.receiveAddress
              this.address2= res.data.obj.address.receiveAddress2


            }).catch((err) => {
                    console.log(err);
                });
        }else {
            //默认大洲
            world.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.continentList.push(obj)
            });
            this.continent1=this.continentList[0].text;
            //默认国家
            world[0].childrens.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.countryList.push(obj)
            });
            this.country1=this.countryList[0].text;
            //默认省份
            world[0].childrens[0].childrens.map((item: any,index: any)=>{
                const obj={value:1,text:''};
                obj.value=index;
                obj.text=item.name_en;
                this.provinceList.push(obj)
            } );
            // this.province1=this.provinceList[0].text
        }
    },
    setup() {
      const route = useRoute();
        const router = useRouter();
      const id = route.query.id || "";
        function back() {
            router.go(-1);
        }
        function save() {
            console.log(this.tel);
            const data=qs.stringify({
                id:id,
                continent:this.continent1,
                province:this.province1,
                country:this.country1,
                city:this.city1,
                receiver:this.receiver,
                contactEmail:this.Email,
                receiveAddress:this.address1,
                receiveAddress2:this.address2,
                contactTel:this.Tel,
                postcode:this.postcode
            });

            if(!this.continent1||!this.province1||!this.country1||!this.city1||!this.receiver||!this.Email||
                !this.address1||!this.address2||!this.Tel||!this.postcode){
                Toast({
                    type: "error",
                    title: 'Please complete the address！'
                });
                return false
            }
            axios.post("/M/User/SaveAddress",data)
                .then((res) => {
                    if (res.data.success) {
                        Toast({
                            type: "success",
                            title: res.data.msg,
                            onClose: () => {
                                back();
                            },
                        });
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
      return {
          id,
          back,
          save
      };

    },
  });
